<template>
  <div>
    <a-modal
      :visible="visible"
      title="关联产品小类"
      width="60%"
      centered
      okText="确认"
      cancelText="取消"
      @ok="() => hideDialog()"
      @cancel="() => hideDialog()"
    >
      <a-table
        size="middle"
        :pagination="true"
        :columns="this.$constRepository.productSubClass.columns"
        :data-source="tableDate"
        class="product-sub-class-table"
        rowKey="productClassId"
      >
        <span slot="type" slot-scope="scope">{{ $constRepository.productSubClass.PRODUCT_SUB_CLASS_TYPE[scope] }}</span>
        <span slot="kind" slot-scope="scope">{{ $constRepository.productSubClass.PRODUCT_SUB_CLASS_KIND[scope] }}</span>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'ProductSubClassesTableModal',
  components: {},
  mounted() {},
  data() {
    return {
      tableDate: null,
      visible: false,
    };
  },
  methods: {
    cancel() {
      this.$router.back();
    },
    hideDialog() {
      this.visible = false;
    },
    showDialog(pararms) {
      this.tableDate = pararms;
      this.visible = true;
    },
  },
};
</script>
